<template>
    <div class="report-page">
        <div class="main-h1">Отчет исполнителя</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">Период</div>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">От</div>
                            <input type="date" v-model="dateFrom" @change="getReport"/>
                        </label>
                    </div>
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">До</div>
                            <input type="date" v-model="dateTo" @change="getReport"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <th>Исполнитель</th>
                        <th>Период</th>
                        <th>Количество завершенных дел</th>
                        <th>Количество одобренных дел</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{report.user_name}}</td>
                            <td>{{dateFromRu}} - {{dateToRu}}</td>
                            <td>{{report.endedCount.toLocaleString()}}</td>
                            <td>{{report.approvedCount.toLocaleString()}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: "reportPage",
    data() {
        return {
            dateFrom: `${moment().format('Y')}-01-01`,
            dateTo:moment().format('Y-MM-DD'),
            report:{
                endedCount:0,
                approvedCount:0,
            },
        }
    },
    methods: {
        getReportImpl() {
            this.$api.get(`report_impl/${this.dateFrom}/${this.dateTo}`).then(res => {
                if (res.data.success) {
                    this.report = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        }
    },
    created() {
        this.getReportImpl();
    },
    computed:{
        dateFromRu() {
            return moment(this.dateFrom).format('DD.MM.Y');
        },
        dateToRu() {
            return moment(this.dateTo).format('DD.MM.Y');
        },
    },
    watch: {
        'dateFrom': function(){
            this.getReportImpl();
        },
        'dateTo': function(){
            this.getReportImpl();
        }
    }
}
</script>

<style scoped>
.btn {
    display: inline-block;
    margin: 20px 0 0;
}
</style>